var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "app" } }, [
    _c("div", { staticClass: "section content-title-group" }, [
      _c("h2", { staticClass: "title" }, [_vm._v(_vm._s(_vm.titulo))]),
    ]),
    _c("span", { staticClass: "texto30px" }, [
      _vm._v(" Edita tu página aquí. "),
    ]),
    _c("br"),
    _c("br"),
    _c("div", { staticClass: "containerCreacionPagina" }, [
      _c("label", { staticClass: "labelCreacionPagina" }, [
        _vm._v(" Selecionar página "),
      ]),
      _c("div", { staticStyle: { float: "left" } }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.paginaseleccionada,
                expression: "paginaseleccionada",
              },
            ],
            attrs: { name: "selecionpagina" },
            on: {
              change: function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.paginaseleccionada = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              },
            },
          },
          _vm._l(_vm.paginasposibles, function (elemento) {
            return _c(
              "option",
              { key: elemento.id, domProps: { value: elemento.id } },
              [_vm._v(" " + _vm._s(elemento.titulo))]
            )
          }),
          0
        ),
        _c(
          "button",
          { attrs: { type: "button" }, on: { click: _vm.cargarPagina } },
          [_vm._v("Cargar")]
        ),
      ]),
      _c("label", { staticClass: "labelCreacionPagina" }, [
        _vm._v("Nombre para URL"),
      ]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.nombreurl,
            expression: "nombreurl",
          },
        ],
        staticClass: "inputCreacionPagina",
        attrs: {
          id: "nombreurl",
          required: "",
          placeholder: "Nombre para url",
        },
        domProps: { value: _vm.nombreurl },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.nombreurl = $event.target.value
          },
        },
      }),
      _c("label", { staticClass: "labelCreacionPagina" }, [
        _vm._v("Nombre para mostrar"),
      ]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.nombreparamostrar,
            expression: "nombreparamostrar",
          },
        ],
        staticClass: "inputCreacionPagina",
        attrs: {
          id: "nombreparamostrar",
          required: "",
          placeholder: "Nombre para mostrar",
        },
        domProps: { value: _vm.nombreparamostrar },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.nombreparamostrar = $event.target.value
          },
        },
      }),
      _c("label", { staticClass: "labelCreacionPagina" }, [
        _vm._v("Parent id"),
      ]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.parentid,
            expression: "parentid",
          },
        ],
        staticClass: "inputCreacionPagina",
        attrs: { id: "parentid", placeholder: "xxxxxxx-yyyyyyyy" },
        domProps: { value: _vm.parentid },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.parentid = $event.target.value
          },
        },
      }),
      _c("label", { staticClass: "labelCreacionPagina" }, [
        _vm._v("Habilitado "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.estahabilitado,
              expression: "estahabilitado",
            },
          ],
          staticClass: "inputCreacionPagina",
          attrs: {
            type: "checkbox",
            id: "estahabilitado",
            checked: "",
            placeholder: "Habilitado",
          },
          domProps: {
            checked: Array.isArray(_vm.estahabilitado)
              ? _vm._i(_vm.estahabilitado, null) > -1
              : _vm.estahabilitado,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.estahabilitado,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.estahabilitado = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.estahabilitado = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.estahabilitado = $$c
              }
            },
          },
        }),
      ]),
      !_vm.estahabilitado
        ? _c("label", { staticClass: "labelCreacionPagina" }, [
            _vm._v(" Fecha de habilitación "),
            _c("input", {
              staticClass: "inputCreacionPagina",
              attrs: {
                id: "fechahabilitacion",
                type: "date",
                placeholder: "Fecha de habilitación",
              },
              domProps: { value: _vm.fecha_habilitacion },
              on: { input: _vm.fecha_habilitacion },
            }),
          ])
        : _vm._e(),
      _c("label", { staticClass: "labelCreacionPagina" }, [
        _vm._v("Incluir página "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.tienepagina,
              expression: "tienepagina",
            },
          ],
          staticClass: "inputCreacionPagina",
          attrs: { type: "checkbox", id: "tienepagina" },
          domProps: {
            checked: Array.isArray(_vm.tienepagina)
              ? _vm._i(_vm.tienepagina, null) > -1
              : _vm.tienepagina,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.tienepagina,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.tienepagina = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.tienepagina = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.tienepagina = $$c
              }
            },
          },
        }),
      ]),
      _vm.tienepagina
        ? _c("label", { staticClass: "labelCreacionPagina" }, [
            _vm._v(" Tipo de página "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.tipopagina,
                    expression: "tipopagina",
                  },
                ],
                attrs: { name: "tipopagina" },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.tipopagina = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              [
                _c("option", { attrs: { value: "texto-normal" } }, [
                  _vm._v("Texto"),
                ]),
                _c(
                  "option",
                  { attrs: { value: "texto-agrupado-en-secciones" } },
                  [_vm._v("Texto agrupado en secciones")]
                ),
              ]
            ),
            _c("br"),
            _vm._v(" Componentes "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.componente_pagina,
                  expression: "componente_pagina",
                },
              ],
              staticClass: "inputCreacionPagina",
              attrs: {
                id: "componente_pagina",
                placeholder: "componente_pagina",
              },
              domProps: { value: _vm.componente_pagina },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.componente_pagina = $event.target.value
                },
              },
            }),
          ])
        : _vm._e(),
      _vm.tienepagina
        ? _c(
            "div",
            [
              _vm._l(_vm.secciones, function (seccion) {
                return _c(
                  "div",
                  { key: seccion.id, staticClass: "panel panel-default" },
                  [
                    _c("br"),
                    _c("p", [_vm._v("Seccion " + _vm._s(seccion.id))]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: seccion.titulo,
                          expression: "seccion.titulo",
                        },
                      ],
                      staticClass: "inputCreacionPagina",
                      attrs: { id: "", placeholder: "Título" },
                      domProps: { value: seccion.titulo },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(seccion, "titulo", $event.target.value)
                        },
                      },
                    }),
                    _c("Editor", {
                      attrs: {
                        id: "editor-" + seccion.id,
                        init: _vm.cfg_tiny,
                        htmlContent: seccion.contenido,
                      },
                    }),
                  ],
                  1
                )
              }),
              _c(
                "button",
                {
                  attrs: { type: "button" },
                  on: { click: _vm.agregarSeccion },
                },
                [_vm._v("Agregar seccion")]
              ),
            ],
            2
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticStyle: {
            "background-color": "white",
            "min-height": "50px",
            "max-height": "350px",
            overflow: "auto",
          },
        },
        [
          _c("div", {
            staticClass: "texto20px textojustificado escroleable400px",
            domProps: { innerHTML: _vm._s(_vm.previsualizacion) },
          }),
        ]
      ),
      _vm.error
        ? _c("p", { staticClass: "error", style: _vm.colorMensaje }, [
            _vm._v(_vm._s(_vm.mensajeResultado)),
          ])
        : _vm._e(),
      _c("input", {
        staticClass: "inputCreacionPagina",
        attrs: { type: "submit", value: "Modificar página" },
        on: { click: _vm.modificarPagina },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }