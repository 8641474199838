<template>
  <div id="app">
    <div class="section content-title-group">
      <h2 class="title">{{ titulo }}</h2>
    </div>
    <!-- eslint-disable-next-line-->
    <span class="texto30px">
      Edita tu página aquí.
      <!--<span class="flecha-doble-invertida texto30px resaltador-celeste"></span
    >--></span>
    <!--<FilterBar :mensajeFiltro="mensajeFiltro" />-->
    <!-- eslint-disable-next-line-->
    </br></br>
   
    <div class="containerCreacionPagina" >
       <label  class="labelCreacionPagina">
      Selecionar página
    </label>
    <div style="float:left;">
    <select v-model="paginaseleccionada" name="selecionpagina" >
    <option v-for="elemento in paginasposibles" v-bind:key="elemento.id" v-bind:value="elemento.id">
          {{elemento.titulo }}</option> 
     </select>
    
    <button type="button" @click="cargarPagina">Cargar</button> 
    </div>
        <label class="labelCreacionPagina">Nombre para URL</label>
            <input
              v-model="nombreurl"
              class="inputCreacionPagina"
              id="nombreurl"
              required
              placeholder="Nombre para url"
            />
            <label class="labelCreacionPagina">Nombre para mostrar</label>
            <input
              v-model="nombreparamostrar"
              class="inputCreacionPagina"
              id="nombreparamostrar"
              required
              placeholder="Nombre para mostrar"
            />
            <label class="labelCreacionPagina">Parent id</label>
            <input
              v-model="parentid"
              class="inputCreacionPagina"
              id="parentid"
              placeholder="xxxxxxx-yyyyyyyy"
            />
            <label class="labelCreacionPagina">Habilitado
            <input
              v-model="estahabilitado"
              class="inputCreacionPagina"
              type="checkbox"
              id="estahabilitado"
              checked
              placeholder="Habilitado"
            />
            </label>
            
            <label  v-if="!estahabilitado"  class="labelCreacionPagina">
                Fecha de habilitación
            <input
                class="inputCreacionPagina"
                id="fechahabilitacion"
                type= "date"
                placeholder="Fecha de habilitación"
                :value="fecha_habilitacion"
                @input="fecha_habilitacion"
                />
            </label>

            <label class="labelCreacionPagina">Incluir página
            <input
              v-model="tienepagina"
              class="inputCreacionPagina"
              type="checkbox"
              id="tienepagina"
            />
            </label>

            <label v-if="tienepagina"  class="labelCreacionPagina">
                Tipo de página
                <select v-model="tipopagina" name="tipopagina">
                    <option value="texto-normal">Texto</option>
                    <option value="texto-agrupado-en-secciones">Texto agrupado en secciones</option>
                </select>
            <!-- eslint-disable-next-line-->
            </br>
            Componentes
            <input
              v-model="componente_pagina"
              class="inputCreacionPagina"
              id="componente_pagina"
              placeholder="componente_pagina"
            />
             
            </label>
            <div v-if="tienepagina">
              
            <div 
              v-for="seccion in secciones"
              v-bind:key="seccion.id"
              class="panel panel-default"
            >
            <!-- eslint-disable-next-line-->
            </br>
             
            <p>Seccion {{seccion.id}}</p>
            <input
              v-model="seccion.titulo"
              class="inputCreacionPagina"
              id=""
              placeholder="Título"
            />
            <Editor :id="'editor-'+seccion.id" :init="cfg_tiny" :htmlContent='seccion.contenido'/>
            </div>
            <button type="button" @click="agregarSeccion">Agregar seccion</button> 
            </div>
              <div class="" style="background-color:white;min-height:50px;max-height:350px;overflow:auto;">
              <div v-html="previsualizacion" class="texto20px textojustificado escroleable400px">

              </div>
              
            </div>
            <p v-if="error" class="error" :style="colorMensaje">{{mensajeResultado}}</p>
            <input type="submit" class="inputCreacionPagina" @click="modificarPagina" value="Modificar página" />
          </div>
          
  </div>
</template>

<script>

// eslint-disable-next-line no-unused-vars
import {_modificarPagina, _obtenerPagina} from './scripts/paginas';
import {mapGetters} from 'vuex';
import Editor from './tiny/tiny-editor';
import Vue from 'vue';
import VueEvents from 'vue-events';
import {_tiny_config_default} from './tiny/config-concepto';
Vue.use(VueEvents);
Vue.component('tiny-editor',Editor);

export default {
  name: 'app',
  components: {
    //FilterBar,
    //MapModal,
    Editor,
  },
    computed: {
    ...mapGetters(['nav']),
  },
  data() {
    return {
      cfg_tiny: _tiny_config_default,
      previsualizacion:'',
      titulo: 'Editar página',
      estahabilitado: true,
      nombreurl: '',
      nombreparamostrar: '',
      esprivado: true,
      error: false,
      tienepagina: false,
      tipopagina: '',
      pagina: '',
      fecha_habilitacion:new Date().toISOString().slice(0,10),
      elementos:{},
      parentid:'',
      componente_pagina:'',
      secciones: [],
      cantidadsecciones:0,
      paginaseleccionada: '',
      paginasposibles:[],
      datosaenviar: {},
      mensajeResultado:'',
      colorMensaje: 'color:red;',
    };
  },
  created() {
    for(let i=0; i< this.nav.length; i++){
      //console.log(this.nav);
      if(this.nav[i].navegacion.tiene_pagina ==='1'){
        this.paginasposibles.push({id:this.nav[i].id_espacio,titulo:'Pagina principal del espacio -' + this.nav[i].navegacion.titulo});
      }
      let secciones = this.nav[i].navegacion.members;
      //console.log(secciones);
      for (let j=0; j < secciones.length; j++){
        if(secciones[j].tiene_pagina ==='1'){
          this.paginasposibles.push({id: secciones[j].id, titulo: 'Pagina principal / sección - ' + secciones[j].titulo});
        }

        let paginas = secciones[j].members;
        //console.log(paginas);
        for (let k = 0; k < paginas.length; k++){
          if(paginas[k].tiene_pagina ==='1'){
          this.paginasposibles.push({id: paginas[k].id, titulo: 'Página - '+ paginas[k].titulo});
          
          }
          let paginasinternas = paginas[k].members;
            for (let l = 0; l < paginasinternas.length; l++){
              if(paginasinternas[l].tiene_pagina ==='1'){
              this.paginasposibles.push({id: paginasinternas[l].id, titulo: 'Subpágina - '+ paginasinternas[l].titulo});
            }
          }
        }
      }
    }
    
  },
  methods: {
        
    previsualizar: function(idEditor,operacion){
      let editor = window.tinymce.editors[idEditor];
      //Este arreglo hay que hacerlo siempre.
      let div = document.createElement('div');
      div.innerHTML = editor.getContent({format: 'raw'});
      /*let elements = div.childNodes;
      for(let i = 0; i< elements.length;i++) {
        try{
        if(elements[i].getAttribute('aria-controls').toString().indexOf('colapsarTexto')>-1){
          elements[i].setAttribute('href', '#'+elements[i].getAttribute('aria-controls'));
          
        }
        // eslint-disable-next-line no-empty
        }catch{}
      }*/
      if(operacion ==='previsualizar'){
        this.previsualizacion = div.innerHTML;
        //this.previsualizacion = div.innerHTML.replaceAll('<span class="separacion20px" style="color: grey;" data-mce-style="color: grey;"> [..] </span>','<span class="separacion20px"></span>');  
        //this.previsualización = this.previsualizacion;
      }
      else if(operacion ==='guardar'){
        this.modificarPagina();
      }
    },
      cargarPagina: function(){
         _obtenerPagina(this.paginaseleccionada)
        .then(resultado => {
          //console.log(resultado);
          if (resultado.status === 200) {
            //this.paginas = resultado.data.data;
            this.datosaenviar = resultado.data.data;
            let obj = resultado.data.data;
            //console.log(obj);
            this.tienepagina = true;
            this.secciones = obj.pagina;
            //console.log(this.secciones);
            this.componente_pagina = obj.componente_pagina;
            this.nombreurl = obj.properties.path;
            this.nombreparamostrar = obj.titulo;
            this.parentid = obj.parent_id;

            this.fecha_habilitacion = new Date(Date.parse(obj.fecha_habilitacion)).toISOString().slice(0,10);
            for(let i = 0; i < this.secciones.length; i++){
              let tinyContainer = window.tinymce.editors['editor-'+this.secciones[i].id];
              tinyContainer.setContent(this.secciones[i].contenido, {format: 'raw'});
            }
            //console.log(this.fecha_habilitacion);
            //this.titulo = obj.titulo;
            //this.ultima_actualizacion = obj.ultima_actualizacion;
            //this.paginaEnviada = obj.pagina;
            //this.render = true;
            //console.log('Resultado: ' + JSON.stringify(resultado));
          } else {
            this.error = true;
            //console.log('ERROR: -> ' + JSON.stringify(resultado));
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(err => {
          this.error = true;
          //console.log('ERROR: -> ' + err);
          return 1;
        });
      },
      agregarSeccion: function(){
        this.cantidadsecciones = this.secciones.length;
        this.cantidadsecciones++;
        //console.log('agregandoSeccion');

        let cantidadsecciones = '' + this.cantidadsecciones;
        this.secciones.push({id:cantidadsecciones, titulo:'',contenido:''});
        //console.log(this.secciones);
      },
      modificarPagina: function(){
          //console.log(this.pagina);
          //let pagina = this.pagina;
          //pagina = pagina.replaceAll('"','\\"');
          //console.log(this.secciones);
          for(let isecs = 0; isecs <  this.secciones.length; isecs++){
            let idEditor = 'editor-' + this.secciones[isecs].id;
            let editor = window.tinymce.editors[idEditor];
            //Este arreglo hay que hacerlo siempre.
            let div = document.createElement('div');
            div.innerHTML = editor.getContent({format: 'raw'});

            let elements = div.childNodes;
            for(let i = 0; i< elements.length;i++) {
              try{
              if(elements[i].getAttribute('aria-controls').toString().indexOf('colapsarTexto')>-1){
                elements[i].setAttribute('href', '#'+elements[i].getAttribute('aria-controls'));
                
              }
              // eslint-disable-next-line no-empty
              }catch{}
            }
            this.secciones[isecs].contenido =  div.innerHTML;
            //this.secciones[isecs].contenido =  div.innerHTML.replaceAll('<span class="separacion20px" style="color: grey;" data-mce-style="color: grey;"> [..] </span>','<span class="separacion20px"></span>');
          }
          
          let pagina = this.secciones;

          //console.log(pagina);
         
          //pagina = this.pagina==='' ?  'NULL' :JSON.parse(pagina);
          let fechahabilitacion = this.fecha_habilitacion === '' ? 'NULL':this.fecha_habilitacion;
          this.parentid = this.parentid==='' ? 'NULL':this.parentid;
          this.componente_pagina = this.componente_pagina ===''?'NULL':this.componente_pagina;
          //console.log(pagina);
          //this.previsualizacion = pagina[0].contenido; 
          
          this.datosaenviar.id= this.paginaseleccionada;
          this.datosaenviar.habilitado= this.estahabilitado;
          this.datosaenviar.fecha_habilitacion= fechahabilitacion;
          this.datosaenviar.pagina= pagina;
          this.datosaenviar.path= this.nombreurl;
          this.datosaenviar.titulo= this.nombreparamostrar;;
          this.datosaenviar.elementos= this.elementos;
          this.datosaenviar.parent_id=this.parentid;
          this.datosaenviar.componente_pagina= this.componente_pagina;
          //console.log(this.datosaenviar);
          _modificarPagina(this.datosaenviar).then(resultado => {
          console.log(resultado);
          if (resultado.status === 200) {  
            console.log('Resultado: ' + JSON.stringify(resultado));
            this.error = true;
            this.colorMensaje = 'color:green;';
            this.mensajeResultado = resultado.msg;
          } else {
            this.error = true;
            this.colorMensaje = 'color:red;';
            console.log('ERROR: -> ' + JSON.stringify(resultado));
            this.mensajeResultado = resultado.msg;
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(err => {
          this.error = true;
          this.colorMensaje = 'color:red;';
          this.mensajeResultado = 'Ocurrió un problema';
          //console.log('ERROR: -> ' + JSON.stringify(resultado));
        });
      },
    cargarModalElemento: function(id, nombreModal) {
      id = id.replace('#', '');
      this.informacion.filter(function(elem) {
        if (elem.element_data_id == id) {
          document.getElementById('textoModal').innerHTML =
            elem.element_data_content;
          document.getElementById('exampleModalLabel').innerText = nombreModal;
          // eslint-disable-next-line no-undef
          $('#exampleModal').modal({backdrop: false});
        }
      });
    },
  },
  mounted(){
    //console.log(window.tinymce);
        // Get the button that opens the modal
    let objectReference = this;
    this.$events.$on('tiny-op', function(id,operacion) {
      objectReference.previsualizar(id,operacion);
    });
  },
};
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.labelCreacionPagina{
     float: left;
     width:100%;
     text-align:left;
}

.inputCreacionPagina, .selectCreacionPagina {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.inputCreacionPagina[type=submit] {
  width: 100%;
  background-color: #04AA6D;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.inputCreacionPagina[type=checkbox] {
  width: 10px;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
}

.inputCreacionPagina[type=submit]:hover {
  background-color: #45a049;
}

.containerCreacionPagina {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
}
</style>